import { AxiosClient } from '@/app/shared/services';

const state = {
  orders: {
    data: [],
  },
  currentOrder: {},
};

const getters = {
  ORDERS: (state) => state.orders.data,
  CURRENT_ORDER: (state) => state.currentOrder,

  // функция для форматирования даты (для всех карточек)
  TEXT_DATE: (state) => (text) => {
    const dateObj = new Date(text);
    const now = new Date();

    const isToday = dateObj.toDateString() === now.toDateString(); // Проверяем, является ли дата сегодняшней

    const userLocale = navigator.language || 'ru-RU';  // Определяем язык пользователя

    const dayMonth = dateObj.toLocaleDateString(userLocale, {
      day: 'numeric',
      month: 'long'
    });

    const time = dateObj.toLocaleTimeString(userLocale, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false  // Задаем автоопределение 12/24 часового формата в зависимости от настроек пользователя
    });

    // Проверяем, 12-часовой или 24-часовой формат
    const timeFormatted = dateObj.toLocaleTimeString(userLocale, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: dateObj.toLocaleTimeString(userLocale, { hour12: true }).includes('AM') || dateObj.toLocaleTimeString(userLocale, { hour12: true }).includes('PM')
    });

    // Если текущий год, то мы его не выводим
    const year = dateObj.getFullYear() !== now.getFullYear() ? `${dateObj.getFullYear()}г. ` : '';

    // Если сегодня, выводим только время, иначе - дату и время
    return isToday ? timeFormatted : `${year}${dayMonth} ${timeFormatted}`;
  },
  REPLACE_BREAK: (state) => (text) => {
    return text.replace(/\n/g, '<br>');
  }
};

const mutations = {
  SET_ORDERS: (state, orders) => {
    state.orders.data = orders;
  },
  SET_CURRENT_ORDER: (state, order) => {
    state.currentOrder = order;
  }
};

const actions = {
  GET_ORDERS: (context) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/orders/history")
        .then((data) => {
          context.commit("SET_ORDERS", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  },
  GET_CURRENT_ORDER: (context, orderId) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/orders/" + orderId + "/")
        .then((data) => {
          context.commit("SET_CURRENT_ORDER", data.data);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка:\n', error);
          reject();
        });
    });
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
